import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/layout-tailwind-mdx.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`IMPORTANT NOTICE:`}</strong></p>
    <p>{`Due to a high volume of orders, please allow 3-5 days for your order to be processed. Once sent out, you can expect to receive an email containing your tracking information that you can use to receive updates on your shipping.`}</p>
    <p>{`If you have any questions at all regarding tracking, feel free to reach out to us and our customer support team will be happy to assist you!`}</p>
    <p>{`Sincerely,`}<br parentName="p"></br>{`
`}{`Anovus Team`}</p>
    <p><strong parentName="p">{`USA ORDERS`}</strong><br parentName="p"></br>{`
`}{`USA orders should arrive between 7-12 business days. Due to high-demand, shipping times will take longer than usual.`}</p>
    <p><strong parentName="p">{`OTHER COUNTRIES`}</strong><br parentName="p"></br>{`
`}{`Please expect your orders to take around 12-18 business days. Every Country has different customs and your item may be held up. Please contact your local shipping service if you believe that your item is being held in customs.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      